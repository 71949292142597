import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import BrandCard from "../components/BrandCard";

export default function Compatible() {
  const [searchParams] = useSearchParams()
  const [brands, setBrands] = useState([])
  const searchValue = searchParams.get('search')
  useEffect(() => {
    let isSubscribed = true
    const fetchData = async () => {
      const { data } = await axios.get(
        searchValue ? '/brands?name=/' + searchValue + '/i' : '/brands'
      )
      if (!isSubscribed) {
        return
      }
      if (data && data.data) {
        setBrands(data.data)
      }
    }
    fetchData()
    return () => {
      isSubscribed = false
    }
  }, [searchValue])

  return (
    <Container className="app-margin-y-130">
      <Row className="justify-content-md-center">
        <Col xxl="6">
          <h2 className="text-center mb-5">Compatible smart light bulbs</h2>
          <Form>
            <InputGroup className="mb-5 app-input-group">
              <InputGroup.Text className="app-radius-14"><i className="fa-solid fa-magnifying-glass"></i></InputGroup.Text>
              <Form.Control
                placeholder="Search"
                className="app-radius-14"
                name="search"
                defaultValue={searchValue}
              />
            </InputGroup>
          </Form>
        </Col>
      </Row>
      <Row>
        {!!brands.length && brands.map(brand => brand && brand.tutorial && (
          <Col xs="6" lg="4" xl="3" className="mb-4" key={brand._id}>
            <BrandCard brand={brand}/>
          </Col>
        ))}
      </Row>
    </Container>
  )
}