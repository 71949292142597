import { Card } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

export default function BrandCard({brand}) {
  if (!brand) {
    return null
  }
  return (
    <Card className="app-card">
      <LinkContainer to={'/brands/' + brand._id}>
        <Card.Body className="app-card-padding text-center">
          {brand.name}
        </Card.Body>
      </LinkContainer>
    </Card>
  )
}
