export default function TutorialDetail({tutorial}) {
  if (!tutorial || !tutorial.videoId) {
    return null
  }
  return (
    <iframe
      width="100%"
      height="480"
      src={`https://www.youtube.com/embed/${tutorial.videoId}`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  )
}