import { Route, Routes } from 'react-router-dom';
import Layout from './Layout';
import BrandDetail from './pages/BrandDetail';
import Compatible from './pages/Compatible';
import Home from './pages/Home';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="brands/:id" element={<BrandDetail />} />
          <Route path="compatible" element={<Compatible />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
