import axios from "axios"
import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import TutorialDetail from "../components/TutorialDetail"

export default function BrandDetail() {
  const { id } = useParams()

  const [brand, setBrand] = useState(null)
  const [tutorials, setTutorials] = useState([])
  useEffect(() => {
    let isSubscribed = true
    const fetchData = async () => {
      const { data: brand } = await axios.get('/brands/' + id)
      if (!isSubscribed || !brand || !brand.data) {
        return
      }
      setBrand(brand.data)
      const { data } = await axios.get('/tutorials?brands=' + id)
      if (!isSubscribed) {
        return
      }
      if (data && data.data) {
        setTutorials(data.data)
      }
    }
    fetchData()
    return () => {
      isSubscribed = false
    }
  }, [id])

  return (
    <div>
      <h2 className="mb-5">
        <Link to="/" className="text-white"><i className="fa-solid fa-xmark"></i></Link>
      </h2>
      {!!brand && <h2 className="text-center mb-5">How to connect {brand.name}</h2>}
      {!!tutorials.length && tutorials.map(tutorial => (
        <TutorialDetail tutorial={tutorial} key={tutorial._id}/>
      ))}
    </div>
  )
}
